<fieldset class="annotation-table">
  <legend>
    Protein domains and properties

    <app-help-link [route]="'/documentation/gene-page-protein-features'"
                   [title]="'Click to view documentation for the Protein domains and properties section'">
    </app-help-link>
  </legend>

<div class="annotation-table-container">
  <div *ngIf="trackViewData.length > 0 && geneDetails.transcripts && geneDetails.transcripts.length > 0 && geneDetails.transcripts[0].protein">
    <div class="protein-feature-title">
      Graphical view
    </div>
    <div class="track-view">
      <app-track-view [protein]="geneDetails.transcripts[0].protein" [trackViewData]="trackViewData"
                      [(highlightedId)]="highlightedId">
      </app-track-view>
    </div>
  </div>

  <app-interpro-matches *ngIf="geneDetails.interpro_matches.length > 0 && geneDetails.uniprot_identifier"
                        [geneDisplayName]="geneDetails.name || geneDetails.uniquename"
                        [uniprotIdentifier]="geneDetails.uniprot_identifier"
                        [matches]="geneDetails.interpro_matches"
                        [(highlightedId)]="highlightedId">
  </app-interpro-matches>

  <div class="tm-domains">
    <div *ngIf="geneDetails.tm_domain_coords.length == 0" class="protein-feature-title">
      No predicted trans-membrane domains
    </div>
    <div *ngIf="geneDetails.tm_domain_coords.length > 0">
      <div class="protein-feature-title">Coordinates of predicted transmembrane domains: </div>
      <span *ngFor="let coord of geneDetails.tm_domain_coords; let lastPair = last">
        {{coord.range.start}}..{{coord.range.end}}<span *ngIf="!lastPair">,</span>
      </span>
    </div>
  </div>

  <div *ngIf="proteinFeaturesTable && proteinFeaturesTable.length > 0" class="pombase-annotation">
    <div class="protein-feature-title">
      Families and domain annotated by {{siteName}}
    </div>
    <app-annotation-sub-table
      [annotationTypeName]="'pombase_family_or_domain'"
      [annotationTable]="proteinFeaturesTable"
      [hideColumns]="['gene', 'desc-rel']">
    </app-annotation-sub-table>
  </div>

  <div *ngIf="soAnnotationTable && soAnnotationTable.length > 0" class="pombase-annotation">
    <div class="protein-feature-title">
      Protein sequence features
    </div>
    <app-annotation-sub-table
      [annotationTypeName]="'sequence'"
      [annotationTable]="soAnnotationTable"
      [detailsOnly]="true"
      [hideColumns]="['gene', 'desc-rel']">
    </app-annotation-sub-table>
  </div>

  <div class="protein-properties"
       *ngIf="geneDetails.transcripts && geneDetails.transcripts.length > 0 && geneDetails.transcripts[0].protein">
    <div class="protein-feature-title">
      Protein properties
    </div>
    <div class="properties-table">
    <app-protein-properties [geneDetails]="geneDetails">
    </app-protein-properties>
    </div>
  </div>
</div>
</fieldset>
